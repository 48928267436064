import React from 'react'
import ReactDOM from 'react-dom'
import { CSSTransitionGroup } from 'react-transition-group'
import Flux from 'flux'
import classNames from 'classnames'
import ReactDnd from 'react-dnd'
import { DragDropContext, DropTarget, DragSource } from 'react-dnd'
import ReactSwipeable from 'react-swipeable'
import ReactImageGallery from 'react-image-gallery'
import DayPickerComponent from 'react-day-picker'
import ReactNumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import lodash from 'lodash'
import Backbone from 'backbone'
import jquery from 'jquery'
import plupload from 'plupload'
import MultiBackend from 'react-dnd-multi-backend'
import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'
import { TouchTransition, MouseTransition } from 'dnd-multi-backend'
import { Tooltip } from 'react-tippy'
import {Elements, PaymentElement, useStripe, ElementsConsumer, useElements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

window.React = React
window.ReactDOM = ReactDOM
window.ReactAddonsCssTransitionGroup = CSSTransitionGroup
window.Flux = Flux
window.classNames = classNames
window.ReactDnd =
  DragDropContext: DragDropContext
  DragSource: DragSource
  DropTarget: DropTarget
window.ReactSwipeable = ReactSwipeable
window.ReactImageGallery = ReactImageGallery
window.DayPickerComponent = DayPickerComponent
window.ReactNumberFormat = ReactNumberFormat
window.PropTypes = PropTypes
window.createReactClass = createReactClass
window._ = lodash
window.jquery = jquery
window.$ = jquery
window.plupload = plupload
window.MultiBackend = MultiBackend
window.HTML5Backend = HTML5Backend
window.TouchBackend = TouchBackend
window.TouchTransition = TouchTransition
window.MouseTransition = MouseTransition
window.Tooltip = Tooltip
window.Elements = Elements
window.useElements = useElements
window.PaymentElement = PaymentElement
window.loadStripe = loadStripe
window.useStripe = useStripe
window.ElementsConsumer = ElementsConsumer
